var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Role ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Role ID","label-for":"roleid"}},[_c('validation-provider',{attrs:{"name":"roleid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"roleid","state":errors.length > 0 ? false : null,"placeholder":"Role ID"},model:{value:(_vm.dataStudent.roleid),callback:function ($$v) {_vm.$set(_vm.dataStudent, "roleid", $$v)},expression:"dataStudent.roleid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Nama Role","label-for":"rolename"}},[_c('validation-provider',{attrs:{"name":"Nama Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"rolename","state":errors.length > 0 ? false : null,"placeholder":"Nama Role"},model:{value:(_vm.dataStudent.rolename),callback:function ($$v) {_vm.$set(_vm.dataStudent, "rolename", $$v)},expression:"dataStudent.rolename"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Deskripsi","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"notes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"notes","state":errors.length > 0 ? false : null,"placeholder":"Deskripsi"},model:{value:(_vm.dataStudent.notes),callback:function ($$v) {_vm.$set(_vm.dataStudent, "notes", $$v)},expression:"dataStudent.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Aktif","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"active","reduce":function (is_active) { return is_active.value; },"options":_vm.dataIsActive,"label":"text","placeholder":"Status Aktif / Tidak Aktif"},model:{value:(_vm.dataStudent.active),callback:function ($$v) {_vm.$set(_vm.dataStudent, "active", $$v)},expression:"dataStudent.active"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }